<template>
  <div class="container">
    <div class="tree">
      <div class="tree-title">
          组织部门
          <el-button @click="showDialog(6,{})" type="primary" size="small" style="height:30px;margin-left:15px">新增</el-button>
      </div>
      <div class="tree-box">
        <Tree
          :data="treeList"
          :opreateId="roleId"
          @deletefn="delTree"
          @edietFn="updateTree"
          @addFn="addTree"
          @getId="getTreeId"
        ></Tree>
      </div>
    </div>
    <div class="right">
      <div class="top">
          <div>视频名称</div>
         <el-input size="small" v-model="options.name"></el-input>
          <div>序列号</div>
          <el-input size="small" v-model="options.deviceSerial"></el-input>
          <el-button @click="search" type="primary" size="small">搜索</el-button>
          <el-button @click="showDialog(0,{})" type="success" size="small">新增视频</el-button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          border
          v-loading="tableLoading"
          size="mini"
          height="717"
          style="width: 100%;font-size:13px"
          :header-cell-style="{ height: '60px', }"
          :cell-style="{padding:'0',height: '55px'}"
        >
          <el-table-column
            prop=""
            label="序号"
            width="60px"
            align="center"
          >
          <template slot-scope="scope" >
              <span>{{scope.$index+1}}</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="视频名称"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="groupName"
            align="center"
            label="组织名称"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="deviceSerial"
            label="序列号"
            align="center"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="deviceSerial"
            label="类型"
            align="center"
            width="80"
          >
          <template slot-scope="scope">
              <div v-if="scope.row.type==1">摄像头</div>
              <div v-else >录像机</div>
          </template>
          </el-table-column>
          <el-table-column
            prop="status"
            label="激活状态"
            align="center"
            width="80"
          >
          <template slot-scope="scope">
              <div v-if="scope.row.status==1">停用</div>
              <div v-else >激活</div>
          </template>
          </el-table-column>
           <el-table-column
            prop="online"
            align="center"
            label="在线状态"
            width="80"
          >
          <template slot-scope="scope">
              <div v-if="scope.row.online==0" class="offonline">离线</div>
              <div v-else class="online">在线</div>
          </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="530"
          >
            <template slot-scope="scope">
              <el-button @click="showDialog(1,scope.row)" type="text" size="medium">修改验证码</el-button>
              <el-button @click="showDialog(2,scope.row)" type="text" size="medium">修改名称</el-button>
              <el-button @click="showDialog(3,scope.row)" type="text" size="medium">修改项目组</el-button>
              <el-button @click="showDialog(8,scope.row)" type="text" size="medium">关闭加密</el-button>
			  <el-button @click="showDialog(9,scope.row)" type="text" size="medium">镜像反转</el-button>
              <el-button @click="showDialog(7,scope.row)" type="text" size="medium">查看版本</el-button>
              <el-button @click="showDialog(4,scope.row)" type="primary " plain class="showbtn" size="mini">查看视频</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
            <Pagination 
                :pageSize="options.pageSize" 
                :pageTotal="totalPage" 
                :pageCurrent.sync="options.pageIndex"
                :pageList="pageList" 
                @handlePageChange="pageChange" 
                @handleSizeChange="pageSizeChange" 
            />
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogFlag"
        :width="dialogWidth"
        destroy-on-close
        @close="closeDialog"
        :close-on-click-modal="false"
        center>
        <!-- 新增视频 -->
        <div v-if="dialogIndex===0">
             <div class="label-box">
                <div>组织</div>
                <el-cascader :props="{ checkStrictly: true }" filterable @change="selectChange" size="small" :options="treeList" :show-all-levels="false"></el-cascader>
            </div>
            <div class="label-box">
                <div>类型</div>
                <el-radio v-model="options2.type" label="1">摄像头</el-radio>
                <el-radio v-model="options2.type" label="2">录像机</el-radio>
            </div>
            <div class="label-box">
                <div>名称</div>
                <el-input size="small" v-model="options2.name"/>
            </div>
            <div class="label-box">
                <div>序列号</div>
                <el-input size="small" v-model="options2.deviceSerial"/>
            </div>
            <div class="label-box">
                <div>验证码</div>
                <el-input size="small" v-model="options2.validCode"/>
            </div>
        </div>
        <!-- 修改验证码 -->
        <div v-if="dialogIndex===1">
            <div class="label-box">
                <div>原验证码</div>
                <el-input size="small" disabled :placeholder="tableRow.validCode"/>
            </div>
            <div class="label-box">
                <div>新验证码</div>
                <el-input size="small" v-model="inputValue"/>
            </div>
        </div>
        <!-- 修改名称 -->
        <div v-if="dialogIndex===2">
            <div class="label-box">
                <div>原名称</div>
                <el-input size="small" disabled :placeholder="tableRow.name"/>
            </div>
            <div class="label-box">
                <div>新名称</div>
                <el-input size="small" v-model="inputValue"/>
            </div>
        </div>
        <!-- 新增项目组 -->
        <div v-if="dialogIndex===3">
            <div class="label-box">
                <div>原项目组</div>
                <el-input size="small" disabled :placeholder="tableRow.groupName"/>
            </div>
            <div class="label-box">
                <div>新项目组</div>
                <el-cascader :props="{ checkStrictly: true }" filterable @change="selectChange2" size="small" :options="treeList" :show-all-levels="false"></el-cascader>
            </div>
        </div>
        <!-- 查看视频 -->
        <div v-if="dialogIndex===4">
            <!-- 选择通道 -->
            <div v-if="videoType===2" class="label-box">
                <span>选择通道:(默认通道1)</span>
                <el-button 
                    type="success"
                    size="mini"
                    v-for="i in 4"
                    :key="i"
                    @click="selectPort(i)"
                > 通道 {{i}}
                </el-button>
            </div>
            <iframe allowfullscreen :src="iframeSrc" allow="geolocation; microphone; camera; midi; encrypted-media;"></iframe>
        </div>
        <!-- 新增项目(有父级) -->
        <div v-if="dialogIndex===5">
            <div class="label-box">
                <div>父级组织</div>
                <el-input size="small" disabled :placeholder="parentName"/>
            </div>
            <div class="label-box">
                <div>组织名称</div>
                <el-input size="small" v-model="inputValue"/>
            </div>
        </div>
        <!-- 新增项目(无父级) -->
        <div v-if="dialogIndex===6">
            <div class="label-box">
                <div>组织名称</div>
                <el-input size="small" v-model="inputValue"/>
            </div>
        </div>
        <!-- 查看版本 -->
        <div v-if="dialogIndex===7">
            <div class="label-box_version">
                <div class="label-box_version_1">
                    <div>当前版本</div>
                    <div>{{versionInfo.currentVersion}}</div>
                </div>
                <div class="label-box_version_1">
                    <div>最新版本</div>
                    <div>{{versionInfo.latestVersion}}</div>
                </div>
                <div class="label-box_version_1">
                    <div>是否需要升级</div>
                    <div>{{versionInfo.isNeedUpgrade==1?"需要":"不需要"}}</div>
                    <el-button  @click="videoVersionUpgrade" type="primary" size="mini" style="margin-left:20px">升级</el-button>
                </div>
                <div class="label-box_version_1">
                    <div>是否正在升级</div>
                    <div>{{versionInfo.isUpgrading==1?"正在升级":"未升级"}}</div>
                </div>
            </div>
        </div>
        <div v-if="dialogIndex===8">
            <div class="encryption">确定要关闭加密吗？</div>
        </div>
		<div v-if="dialogIndex===9">
            <div class="encryption">确定要镜像反转吗？</div>
        </div>
        <span slot="footer" class="dialog-footer" v-if="dialogIndex !== 4">
            <el-button type="warning" @click="dialogFlag = false">取 消</el-button>
            <el-button type="primary" :loading="submitLoading" @click="save">{{submitLoading?'加载中':'确 定'}}</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import Tree from "../../components/Tree";
export default {
  components: { Tree },
  data() {
    return {
      options: {
        pageSize: 10,
        pageIndex: 1,
        deviceSerial:'',
        groupId:'',
        groupName:'',
        name:'',
      },
      options2:{
        id: '',
        groupId: '',
        name: '',
        online:'',
        port:1,
        type: "1",
        validCode: 'yifanghuizhi',
        deviceSerial: '',
      },
      groupId: '',
      dialogTitle: '',
      dialogFlag: false,
      dialogIndex: 0,
      parentName:'',
      parentId:'',
      inputValue:'',
      tableRow:{},
      totalPage: 0,
      pageList: [10, 20, 50, 100],
      roleId: 0,
      treeList: [],
      selectTreeList:[],
      tableData: [],
      tableLoading:false,
      submitLoading:false,
      iframeSrc: "",
      dialogWidth:'30%',
      videoType:1,
      versionInfo:{},
    };
  },
  methods: {
    // 获取视频树
    getVideoGroupTree(){
        this.$api.videoGroupTree().then(res=>{
            if(res.code === 200){
                function recursion (arr){
                    for(let i=0; i<arr.length; i++){
                        arr[i].value = arr[i].id;
                        arr[i].label = arr[i].groupName;
                        if(arr[i].children && arr[i].children.length>0){
                            recursion(arr[i].children);
                        }
                    }
                }
                recursion(res.data)
                this.treeList = res.data
            }
        })
    },
    // 修改视频树
    updateVideoGroupTree(options){
        this.submitLoading = true;
        this.$api.videoGroupSave(options).then(res=>{
            this.dialogFlag = false;
            this.submitLoading = false;
            if(res.code === 200){
                this.$message.success(res.message);
                this.getVideoGroupTree();
            }else{
                this.$message.error(res.message);
            }
        })
    },
    // 删除视频树
    deleteVideoGroupTree(id){
        this.$api.videoGroupDelete({id}).then(res=>{
            this.dialogFlag = false;
            if(res.code === 200){
                this.$message.success(res.message);
                this.getVideoGroupTree();
            }else{
                this.$message.error(res.message);
            }
        })
    },
    // 获取视频列表
    getvideoPage(options){
        this.tableLoading = true;
        this.$api.videoPage(options).then(res=>{
            this.tableLoading = false;
            if(res.code === 200){
                this.tableData = res.data.content;
                this.totalPage = res.data.totalElements;
            }
        })
    },
    // 编辑/新增视频
    updateVideoList(options){
        this.submitLoading = true;
        this.$api.videoSave(options).then(res=>{
            this.dialogFlag = false;
            this.submitLoading = false;
            if(res.code === 200){
                this.$message.success(res.message);
                this.getvideoPage(this.options);
            }else{
                this.$message.error(res.message);
            }
        })
    },
    // 选择通道
    selectPort(index){
        let url = `ezopen://${!this.tableRow.validCode? '' : this.tableRow.validCode + '@'}open.ys7.com/${this.tableRow.deviceSerial}/${index}.live`
        this.iframeSrc = `https://oss.yfhuizhi.net:9001/DeviceImitate/NewVideo?accesstoken=${this.tableRow.accessToken}&url=${url}`;
    },
    // 选择组织
    selectChange(val){
        this.options2.groupId = val[val.length-1]
    },
    // 选择组织
    selectChange2(val){
        this.groupId = val[val.length-1]
    },
    // 搜索
    search(){
        this.options.pageIndex = 1;
        this.options.groupId = '';
        this.getvideoPage(this.options);
    },
    // 关闭dialog
    closeDialog(){
        this.iframeSrc = ''
    },
    // 查看版本
    videoVersionInfo(deviceSerial){
        this.$api.videoVersionInfo({deviceSerial}).then(res=>{
            this.versionInfo = res.data;
        })
    },
    // 升级版本
    videoVersionUpgrade(){
        this.$confirm('确认升级设备？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.$api.videoVersionUpgrade({deviceSerial:this.tableRow.deviceSerial}).then(res=>{
                this.dialogFlag = false;
                if(res.code === 200){
                    this.$message.success(res.message);
                }else{
                    this.$message.error(res.message);
                }
            })
        })
        
    },
    // /关闭加密
    videoEncryptOff(deviceSerial){
        this.submitLoading = true;
        this.$api.videoEncryptOff({deviceSerial}).then(res=>{
            this.submitLoading = false;
            this.dialogFlag = false;
            if(res.code === 200){
                this.$message.success(res.message);
            }else{
                this.$message.error(res.message);
            }
        })
    },
	// 镜像反转
    videoMirror(deviceSerial){
        this.submitLoading = true;
        this.$api.videoMirror({deviceSerial}).then(res=>{
            this.submitLoading = false;
            this.dialogFlag = false;
            if(res.code === 200){
                this.$message.success(res.message);
            }else{
                this.$message.error(res.message);
            }
        })
    },
    getTreeId(val) {
        setTimeout(()=>{
            this.options.groupId = val;
            this.getvideoPage(this.options);
        },)
    },
    delTree(val) {
        setTimeout(()=>{
            this.deleteVideoGroupTree(val.id)
        },)
    },
    updateTree(val) {
        setTimeout(()=>{
            let options = {
                id: val.data.id,
                parentId: val.data.parentId,
                groupName: val.data.groupName,
            }
            this.updateVideoGroupTree(options)
        },)
    },
    addTree(val) {
        setTimeout(()=>{
            this.showDialog(5,val.data)
        },)
    },
    pageChange(val) {
        this.options.pageIndex = val;
        this.getvideoPage(this.options);
    },
    pageSizeChange(val) {
        this.options.pageSize = val;
        this.getvideoPage(this.options);
    },
    // 0: 新增视频,1:修改验证码,2:修改名称,3:修改项目组,4:查看视频,5:新增项目(组织部门),6:新增项目(组织树),7:查看版本
    showDialog(index,item){
        this.dialogFlag = true;
        this.dialogIndex = index;
        this.inputValue = '';
        this.tableRow = item;
        if(index === 4){
            this.dialogWidth = '50%'
        }else{
            this.dialogWidth = '30%'
        }
        switch(index){
            case 0:
                this.dialogTitle = '新增视频';
                this.options2 = {
                    id: '',
                    groupId: '',
                    name: '',
                    online:'',
                    port:1,
                    type: "1",
                    validCode: 'yifanghuizhi',
                    deviceSerial: '',
                }
                break;
            case 1:
                this.dialogTitle = '修改验证码';
                break;
            case 2:
                this.dialogTitle = '修改名称';
                break;
            case 3:
                this.dialogTitle = '修改项目组';
                break;
            case 4:
                this.dialogTitle = '查看视频';
                this.videoType = item.type;
                let url = `ezopen://${!item.validCode? '' : item.validCode + '@'}open.ys7.com/${item.deviceSerial}/${item.port}.live`
                this.iframeSrc = `https://oss.yfhuizhi.net:9001/DeviceImitate/NewVideo?accesstoken=${item.accessToken}&url=${url}`;
                break;
            case 5:
                this.dialogTitle = '新增项目';
                this.parentName = item.groupName;
                this.parentId = item.id;
                break;
            case 6:
                this.dialogTitle = '新增项目';
                break;
            case 7:
                this.dialogTitle = '查看版本';
                this.videoVersionInfo(item.deviceSerial);
                break;
            case 8:
                this.dialogTitle = '关闭加密';
                break;
			case 9:
                this.dialogTitle = '镜像反转';
                break;
        }
    },
    // dialog保存
    save(){
        switch(this.dialogIndex){
            // 新增视频
            case 0:
                if(!this.options2.groupId){
                    this.$message.error('请选择组织!');
                    return
                }
                if(!this.options2.name){
                    this.$message.error('请输入名称!');
                    return
                }
                if(!this.options2.deviceSerial){
                    this.$message.error('请输入序列号!');
                    return
                }
                if(!this.options2.validCode){
                    this.$message.error('请输入验证码!');
                    return
                }
                this.options2.id = '';
                this.updateVideoList(this.options2)
                break;
            // 修改验证码
            case 1:
                if(!this.inputValue){
                    this.$message.error('输入为空!');
                    return
                }
                this.options2 = {
                    id: this.tableRow.id,
                    groupId: this.tableRow.groupId,
                    name: this.tableRow.name,
                    port: this.tableRow.port,
                    online: this.tableRow.online,
                    type: this.tableRow.type,
                    validCode: this.inputValue,
                    deviceSerial: this.tableRow.deviceSerial,
                }
                this.updateVideoList(this.options2)
                break;
            // 修改名称
            case 2:
                if(!this.inputValue){
                    this.$message.error('输入为空!');
                    return
                }
                this.options2 = {
                    id: this.tableRow.id,
                    groupId: this.tableRow.groupId,
                    name: this.inputValue,
                    port: this.tableRow.port,
                    online: this.tableRow.online,
                    type: this.tableRow.type,
                    validCode: this.tableRow.validCode,
                    deviceSerial: this.tableRow.deviceSerial,
                }
                this.updateVideoList(this.options2)
                break;
            // 修改项目组
            case 3:
                if(!this.groupId){
                    this.$message.error('输入为空!');
                    return
                }
                this.options2 = {
                    id: this.tableRow.id,
                    groupId: this.groupId,
                    name: this.tableRow.name,
                    port: this.tableRow.port,
                    online: this.tableRow.online,
                    type: this.tableRow.type,
                    validCode: this.tableRow.validCode,
                    deviceSerial: this.tableRow.deviceSerial,
                }
                this.updateVideoList(this.options2)
                break;
            // 查看视频
            case 4:
                
                break;
            // 有父级新增项目
            case 5:
                var options = {
                    id:'',
                    parentId: this.parentId,
                    groupName: this.inputValue,
                }
                this.updateVideoGroupTree(options)
                break;
            // 无父级新增项目
            case 6:
                if(!this.inputValue){
                    this.$message.error('输入为空!');
                    return
                }
                var options = {
                    id:'',
                    parentId:'',
                    groupName: this.inputValue,
                }
                this.updateVideoGroupTree(options)
                break;
            case 7:
                this.dialogFlag = false;
                break;
            case 8:
                this.videoEncryptOff(this.tableRow.deviceSerial)
                break;
			case 9:
                this.videoMirror(this.tableRow.deviceSerial)
                break;
        }
    }
  },
  mounted() {
    this.getvideoPage(this.options)
    this.getVideoGroupTree();
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
iframe {
    width: 100%;
    height: 600px;
    border: none;
    background: black;
}
.tree {
  width: 320px;
  height: 100%;
  border-right: 10px solid #f4f6fa;
}
.tree-title {
  height: 35px;
  padding-left: 10px;
  margin-top: 10px;
  font-size: 17px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.tree-box {
  height: 775px;
  width: 320px;
  overflow: auto;
}
.right {
  height: 820px;
}
.top{
    display: flex;
    align-items: center;
    height: 50px;
    div{
        margin: 0 10px;
    }
    .el-input,.el-autocomplete{
        width: 230px;
    }
    .el-button{
        height: 30px;
        min-width: 65px;
        margin-left: 20px;
    }
}
.table-box {
  width: 1365px;
}
.page{
    display: flex;
    justify-content: center;
}
.online, .offonline{
    margin:0 auto;
    border-radius:2px; 
    width:50px;
    height:20px;
    line-height: 20px;
    background:rgba(133,209,97,0.3);
    color:rgba(133,209,97,0.9)
}
.offonline{
    background:rgba(255,0,0,0.3);
    color:rgba(255,0,0,0.9)
}
.label-box{
    display: flex;
    align-items: center;
    margin-left: 3%;
    margin-bottom: 20px;
    div{
        width: 80px;
        text-align: left;
    }
    .el-input,.el-cascader{
        width: 400px;
    }
    span{
        font-weight: bold;
        font-size: 15px;
        margin-right: 20px;
    }
}
.label-box_version{
    .label-box_version_1{
        display: flex;
        align-items: center;
        margin: 15px 0;
        font-size: 15px;
        div:nth-child(1){
            width: 100px;
            margin-right: 30px;
            text-align: right;
        }
    }
}
.encryption{
    font-size: 17px;
}
::v-deep .showbtn{
    width: 57px !important;
    height: 25px !important;
    padding: 0;
}
::v-deep div::-webkit-scrollbar,
ul::-webkit-scrollbar {
  width: 9px !important;
  height: 10px !important;
}
::v-deep div::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track {
  background-color: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
::v-deep div::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb {
  background-color: #e3e3e3;
  border-radius: 3;
  -webkit-border-radius: 3;
  -moz-border-radius: 3;
  // width: 2px;
  // height: 2px;
}
</style>